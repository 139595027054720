import React from 'react';
import { Phone, Mail, Home, DollarSign, Clock, ThumbsUp } from 'lucide-react';

const LandingPage = () => {
  return (
      <div className="min-h-screen bg-gray-100">
        <header className="bg-blue-600 text-white p-4">
          <div className="container mx-auto flex justify-between items-center">
            <h1 className="text-2xl font-bold">The Real Estate Dream to Own</h1>
            <nav>
              <ul className="flex space-x-4">
                <li><a href="#about" className="hover:underline">About</a></li>
                <li><a href="#services" className="hover:underline">Services</a></li>
                <li><a href="#contact" className="hover:underline">Contact</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <main className="container mx-auto mt-8 px-4">
          <section className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Your Path to Home Ownership Starts Here</h2>
            <p className="text-xl">We help buyers secure homes faster and sellers maximize their property's value.</p>
          </section>

          <section id="about" className="mb-12">
            <h3 className="text-2xl font-semibold mb-4">About Us</h3>
            <p className="mb-4">At Real Estate Dream to Own, we're committed to making home ownership a reality for everyone. Our innovative approach bridges the gap between buyers and sellers, creating win-win situations.</p>
          </section>

          <section id="services" className="mb-12">
            <h3 className="text-2xl font-semibold mb-4">Our Services</h3>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h4 className="text-xl font-semibold mb-2">For Buyers</h4>
                <ul className="space-y-2">
                  <li className="flex items-center"><DollarSign className="mr-2 text-green-500" /> No interest payments</li>
                  <li className="flex items-center"><Home className="mr-2 text-green-500" /> Affordable monthly payments</li>
                  <li className="flex items-center"><Clock className="mr-2 text-green-500" /> Own your home in 7-15 years</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h4 className="text-xl font-semibold mb-2">For Sellers</h4>
                <ul className="space-y-2">
                  <li className="flex items-center"><DollarSign className="mr-2 text-blue-500" /> Earn extra monthly income</li>
                  <li className="flex items-center"><Clock className="mr-2 text-blue-500" /> Less time waiting for a buyer</li>
                  <li className="flex items-center"><ThumbsUp className="mr-2 text-blue-500" /> Help others achieve homeownership</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="contact" className="mb-12">
            <h3 className="text-2xl font-semibold mb-4">Contact Us</h3>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p className="mb-4">Ready to get started? Contact Peter Soares Jr. today for a free consultation!</p>
              <ul className="space-y-2">
                <li className="flex items-center"><Phone className="mr-2 text-blue-600" /> 808-754-6326</li>
                <li className="flex items-center"><Mail className="mr-2 text-blue-600" /> petersoaresjr@gmail.com</li>
                <li className="flex items-center"><Home className="mr-2 text-blue-600" /> www.redream2own.com</li>
              </ul>
            </div>
          </section>
        </main>

        <footer className="bg-gray-800 text-white p-4 mt-12">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Real Estate Dream to Own. All rights reserved.</p>
          </div>
        </footer>
      </div>
  );
};

export default LandingPage;